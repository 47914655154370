function URLTemplate(strings, ...keys) {
    return (...values) => {
        const dict = values[values.length - 1] || {};
        const result = [strings[0]];
        keys.forEach((key, i) => {
            const value = Number.isInteger(key) ? values[key] : dict[key];
            result.push(value, strings[i + 1]);
        });
        return result.join('');
    };
}

export const roleApis = {
    getRolesByFilters: URLTemplate`api/roles?filter_by_role_group_type=${'role_filter'}&filter_by_role_active_status=${'status'}&offset=${'startRecords'}&limit=${'to_value'}`,
    getRoles: 'api/roles',
    getFeatures: 'api/features',
    getAllUserFeatures: 'api/features/all',
    getRoleById: URLTemplate`api/roles/${'role_id'}/features/all`,
    viewRoleById: URLTemplate`api/roles/${'role_id'}`,
    createRole: 'api/roles',
    updateRole: 'api/roles',
    deleteRole: URLTemplate`api/roles/${'role_id'}/deactivate`,
    reactivateRole: URLTemplate`api/roles/${'role_id'}/reactivate`,
    getRolesForFilter: 'api/roles/filter',
}
export const UserApis = {
    getUser: 'api/users',
    getUserById: URLTemplate`api/users/${'user_id'}`,
    updateUser: 'api/users',
    createUser: 'api/users',
    userOtp: 'api/users/signup/otp/generate',
    getStates: 'api/states',
    getBranches: 'api/branches',
    getCities: URLTemplate`api/states/${'stateId'}/cities`,
    getUserByFilter: URLTemplate`api/users?filter_by_role_id=${'filter_by_role_id'}&filter_by_user_active_status=${'filter_by_user_active_status'}&offset=${'offset'}&limit=${'limit'}`,
    deleteUser: URLTemplate`api/users/${'user_id'}/deactivate`,
    reactivateUser: URLTemplate`api/users/${'user_id'}/reactivate`,
    generateOtpUserUpdate: URLTemplate`api/users/${'user_id'}/update/otp/generate`,
    updateUserFilesURL: URLTemplate`api/users/${'user_id'}/file-urls/update`,
    updateUserRoleURL: 'api/users/update-role'
}
export const branchApis = {
    // getBranchesByFilter:'api/branches', 
    getBranchesByFilter: URLTemplate`api/branches?filter_by_branch_id=${'filter_by_branch_id'}&filter_by_branch_active_status=${'filter_by_branch_active_status'}&offset=${'offset'}&limit=${'limit'}`,
    createBranch: 'api/branches',
    updateBranch: 'api/branches',
    getBranchById: URLTemplate`api/branches/${'branch_id'}`,
    deactivateBranch: URLTemplate`api/branches/${'branch_id'}/deactivate`,
    reactivateBranch: URLTemplate`api/branches/${'branch_id'}/reactivate`,
    getUserByBranch: URLTemplate`api/branches/${'branch_id'}/users`,
    updateBranchFilesURL: URLTemplate`api/branches/${'branch_id'}/file-urls/update`,
}
export const userProfile = {
    getUserProfile: 'api/users/profile',
    updateUserProfile: 'api/users/profile'
}
export const planTypes = {
    getPlanTypes: 'api/plan-types',
    getPlanTypeById: URLTemplate`api/plan-types/${'plan_type_id'}`,
    reactivatePlanType: URLTemplate`api/plan-types/${'plan_type_id'}/reactivate`,
    deactivatePlanType:URLTemplate`api/plan-types/${'plan_type_id'}/deactivate`,
    updatePlanType: `api/plan-types`,
    createPlanType: `api/plan-types`
}
export const planSubTypes = {
    getPlanSubTypes: 'api/plan-subtypes',
    getPlanSubTypeById: URLTemplate`api/plan-subtypes/${'plan_subtype_id'}`,
    reactivatePlanSubType: URLTemplate`api/plan-subtypes/${'plan_subtype_id'}/reactivate`,
    deactivatePlanSubType:URLTemplate`api/plan-subtypes/${'plan_subtype_id'}/deactivate`,
    updatePlanSubType: `api/plan-subtypes`,
    createPlanSubType: URLTemplate`api/plan-subtypes/${'plan_type_id'}`
}
export const plans = {
    getPlans: 'api/plans',
    getPlansById: URLTemplate`api/plans/${'plan_id'}`,
    reactivatePlans: URLTemplate`api/plans/${'plan_id'}/reactivate`,
    deactivatePlans:URLTemplate`api/plans/${'plan_id'}/deactivate`,
    updatePlans: `api/plans`,
    createPlans:`api/plans`,
    createCalculatePlansPrice: `api/plans/price/calculate`,
    sacCodes:`api/plans/sac-codes`
}
export const AddOnPlans = {
    getAddOnPlans: 'api/addons',
    getAddOnPlansById: URLTemplate`api/addons/${'addon_plan_id'}`,
    reactivateAddOnPlans: URLTemplate`api/addons/${'addon_plan_id'}/reactivate`,
    deactivateAddOnPlans:URLTemplate`api/addons/${'addon_plan_id'}/deactivate`,
    updateAddOnPlans: `api/addons`,
    createAddOnPlans:`api/addons`,
}
export const AddOnTypes = {
    getAddOnTypes: 'api/addon-types',
    getAddOnTypesById: URLTemplate`api/addon-types/${'addon_plan_type_id'}`,
    reactivateAddOnTypes: URLTemplate`api/addon-types/${'addon_plan_type_id'}/reactivate`,
    deactivateAddOnTypes:URLTemplate`api/addon-types/${'addon_plan_type_id'}/deactivate`,
    updateAddOnTypes: `api/addon-types`,
    createAddOnTypes:`api/addon-types`,
}
export const attendanceApi = {
    getAttendance: 'api/users/attendance/last/active',
    getUserAttendanceById: URLTemplate`api/users/${'user_id'}/attendance/logs/statistics`,
    getLogsById: URLTemplate`api/users/${'user_id'}/attendance/logs`,
    updateLogsById: URLTemplate`api/users/${'user_id'}/attendance/logs`,
    logsReport:'api/users/attendance/logs/report',
}
export const deviceApi = {
    getDevicesList:'api/attendance/devices',
    createDevice:'api/attendance/devices',
    getDeviceById: URLTemplate`api/attendance/devices/${'attendance_device_id'}`,
    updateDevice: 'api/attendance/devices',
    deactivateDevice: URLTemplate`api/attendance/devices/${'attendance_device_id'}/deactivate`,
    reactivateDevice: URLTemplate`api/attendance/devices/${'attendance_device_id'}/reactivate`,
}
export const levelApi={
    getAllLevels:'api/levels',
    createLevel:'api/levels',
    updateLevel:'api/levels',
}
export const userBranches ={
    getUserBranches: URLTemplate`api/users/${'user_id'}/branches`,
    getUserDevices:URLTemplate`api/users/${'user_id'}/devices`,
}

export const schedules = {
    activeSchedulesList: 'api/schedules/plans',
    userSchedulesList: 'api/schedules',
    schedulesUserOrderPlanInfo: URLTemplate`api/schedules/plan/${'user_order_plan_id'}`,
    scheduleById: URLTemplate`api/schedules/${'slot_booking_id'}`,
    getAvailableTimeSlotsToCreateupdateSchedule: 'api/schedules/time-slots',
    SchedulesAvailableTrainers: 'api/schedules/available-trainers',
    createSchedule: URLTemplate`api/schedules/${'user_order_plan_id'}`,
    updateSchedule: URLTemplate`api/schedules/${'slot_booking_id'}`
}

export const reschedules = {
    rescheduleRequests: 'api/reschedules',
    rescheduleInfoById: URLTemplate`api/reschedules/${'reschedule_slot_booking_id'}`,
    updateReschedule: URLTemplate`api/reschedules/${'reschedule_slot_booking_id'}`,
}

export const reassignments = {
    reassignmentseRequests: 'api/reassignments/all',
}

export const LeavesApi = {
    getLeavesList: 'api/leaves',
    getUsersListById: URLTemplate`api/leaves/users/${'user_id'}`,
    getLeaveById: URLTemplate`api/leaves/${'leave_id'}`,
    updateLeavesList: `api/leaves/${'leave_id'}`,
    createLeave: `api/leaves/user`,
    updateLeave: 'api/leaves',
    createLeavesStatus:`api/leaves`,
    updateLeaveFilesURL: URLTemplate`api/leaves/${'leave_id'}/file-urls/update`,
}

export const UserPayables = {
    getUserPayableList: 'api/user-payables',
    getUserPayableListById: URLTemplate`api/user-payables/${'user_payable_id'}`,
    updateUserPayableList: `api/user-payables`,
    createUserPayableList:`api/user-payables`,
}

export const CouponTypes = {
    getCouponTypes: 'api/coupon-types',
    getCouponTypesById: URLTemplate`api/coupon-types/${'coupon_type_id'}`,
    reactivateCouponTypes: `api/coupon-types`,
    deactivateCouponTypes:`api/coupon-types`,
    updateCouponTypes: `api/coupon-types`,
    createCouponTypes: `api/coupon-types`
}

export const Coupons = {
    getCoupons: 'api/coupons',
    getCouponsById: URLTemplate`api/coupons/${'coupon_id'}`,
    reactivateCoupons: URLTemplate`api/coupons/${'coupon_id'}/reactivate`,
    deactivateCoupons:URLTemplate`api/coupons/${'coupon_id'}/deactivate`,
    updateCoupon: `api/coupons`,
    createCoupon: `api/coupons`
}

export const Tickets = {
    getTickets: 'api/support/tickets',
    getTicketById: URLTemplate`api/support/tickets/${'ticket_id'}`,
    updateTickets: 'api/support/tickets',
    createTickets: 'api/support/tickets',
    updateTicketFilesURL: URLTemplate`api/support/tickets/${'ticket_id'}/file-urls/update`,
}

export const Tags = {
    getTagById: URLTemplate`api/support/tag/${'tag_id'}`,
    updateTags: 'api/support/tags',
    createTags: 'api/support/tags',
    getTags: 'api/support/tags',
    deactivateTag: URLTemplate`api/support/tags/${'tag_id'}/deactivate`,
    reactivateTag: URLTemplate`api/support/tags/${'tag_id'}/reactivate`,

}

export const TimeslotDiscounts = {
    getTimeslotDiscounts: 'api/timeslot-discounts',
    getTimeslotDiscountById: URLTemplate`api/timeslot-discounts/${'timeslot_discount_id'}`,
    updateTimeslotDiscounts: 'api/timeslot-discounts',
    createTimeslotDiscounts: 'api/timeslot-discounts',
    timeSlotAliases: 'api/timeslot-discounts/time_slots',
    deactivateTimeslotDiscounts: URLTemplate`api/timeslot-discounts/${'timeslot_discount_id'}/deactivate`,
    reactivateTimeslotDiscounts: URLTemplate`api/timeslot-discounts/${'timeslot_discount_id'}/reactivate`,
}

export const fileServerAPIUrls = {
    getFiles: 'api/file-server/get-files',
    uploadFiles: 'api/file-server/upload-files',
    deleteFiles: 'api/file-server/delete-files',
}

export const userOrdersUrls = {
    cancelPlanInfo: 'api/user-orders/plans/cancel/info',
    cancelPlan: 'api/user-orders/plans/cancel',
    getAvailableTrainersToUpdatePlanTrainer: URLTemplate`api/user-orders/${'user_order_plan_id'}/trainers/available`,
    updatePlanTrainer: URLTemplate`api/user-orders/${'user_order_plan_id'}/trainers/update`,
    extendUserOrderPlan: URLTemplate`api/schedules/plan/${'user_order_plan_id'}/extend`,
    getAvailableTimeSlotsToUpdatePlanTimeAndTrainer: URLTemplate`api/user-orders/${'user_order_plan_id'}/time-trainer/available/time-slots`,
    getAvailableTimeSlotTrainersToUpdatePlanTimeAndTrainer: URLTemplate`api/user-orders/${'user_order_plan_id'}/time-trainer/${'time_slot'}/available/trainers`,
    updatePlanTimeTrainer: URLTemplate`api/user-orders/${'user_order_plan_id'}/time-trainer/update`,
}

export const userWalletUrls = {
    userWalletTransactions: 'api/user-wallets/transactions',
}

export const referralPercentagesUrls = {
    getReferralPercentages: 'api/referral-percentages',
    getReferralPercentagesById: URLTemplate`api/referral-percentages/${'referralPercentagesId'}`,
    createReferralPercentages: 'api/referral-percentages',
    editReferralPercentages: URLTemplate`api/referral-percentages/${'referralPercentagesId'}`,
    deactivateReferralPercentages: URLTemplate`api/referral-percentages/${'referralPercentagesId'}/deactivate`,
    reactivateReferralPercentages: URLTemplate`api/referral-percentages/${'referralPercentagesId'}/reactivate`,
}

export const userAccessesApis = {
    getUserAccessesWithAllAccessesByUserId: URLTemplate`api/user-accesses/${'user_id'}/features/all`,
    getUserAccessesById: URLTemplate`api/user-accesses/${'user_id'}`,
    updateUserAccessesByUserId: URLTemplate`api/user-accesses/${'user_id'}`,
}


export const broadcastAPIUrls = {
    createCustomNotificaton: 'api/notifications/custom',
    getCustomNotificatons: 'api/notifications/custom',
    getCustomNotificatonById: URLTemplate`api/notifications/custom/${'customNotificationId'}`,
}

export const steelBannerAPIUrls = {
    getSteelBanners: 'api/steel-banners',
    createSteelBanner: 'api/steel-banners',
    getSteelBannerById: URLTemplate`api/steel-banners/${'steelBannerId'}`,
    editSteelBanner: URLTemplate`api/steel-banners/${'steelBannerId'}`,
    deactivateSteelBanner: URLTemplate`api/steel-banners/${'steelBannerId'}/deactivate`,
    reactivateSteelBanner: URLTemplate`api/steel-banners/${'steelBannerId'}/reactivate`,
}

export const purchaseHistoryAPIUrls = {
    getAllPurchasehistory: 'api/user-orders/purchase-history/all',
    getPlanExpiryRenewalTracks: 'api/user-orders/purchase-history/expiry-renewal-track'
}

export const planWaitlistsAPIUrls = {
    getAllPlanWaitlists: 'api/plans/waitlists',
}

export const freezeAppAlertsAPIUrls = {
    getFreezeAppAlerts: 'api/freeze-app-alerts',
    createFreezeAppAlerts: 'api/freeze-app-alerts',
    getFreezeAppAlertById: URLTemplate`api/freeze-app-alerts/${'freezeAppAlertId'}`,
}

export const fitnessScoreAPIs = {
    getFitnessScoresByFilters: 'api/fitness-stats/users/fitness-scores',
    getUserFitnessScoreStat: URLTemplate`api/fitness-stats/${'steelScoreId'}`
}