import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { UserManagementComponent } from './pages/Users/user-management/user-management.component';
import { RolesListComponent } from './pages/Role/roles-list/roles-list.component';
import { CreateRoleComponent } from './pages/Role/create-role/create-role.component';
import { CreateUserComponent } from './pages/Users/create-user/create-user.component';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import { ViewRoleComponent } from './pages/Role/view-role/view-role.component';
import { ViewUserComponent } from './pages/Users/view-user/view-user.component';
import { BranchesListComponent } from './pages/branches/branches-list/branches-list.component';
import { CreateBranchComponent } from './pages/branches/create-branch/create-branch.component';
import { MatTimepickerModule } from 'mat-timepicker';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { SearchPipe } from './search.pipe';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { NgOtpInputModule } from 'ng-otp-input';
import { OtpConformationComponent } from './pages/Users/otp-conformation/otp-conformation.component';
import { DatePipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { ViewBranchComponent } from './pages/branches/view-branch/view-branch.component';
import { MatSortModule } from '@angular/material/sort';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ProfileComponent } from './pages/profile/profile.component';
import { FuseCardModule } from '@fuse/components/card';
import { AttendanceListComponent } from './pages/attendance/attendance-list/attendance-list.component';
import { AddAttendanceComponent } from './pages/attendance/add-attendance/add-attendance.component';
import { ViewUserAttendanceComponent } from './pages/attendance/view-user-attendance/view-user-attendance.component';
import { CreateLogsComponent } from './pages/attendance/create-logs/create-logs.component';
import { ViewUserLogsComponent } from './pages/attendance/view-user-logs/view-user-logs.component';
import { DevicesComponent } from './pages/biometric-Device/devices/devices.component';
import { CreateDeviceComponent } from './pages/biometric-Device/create-device/create-device.component';
import { AssignDeviceComponent } from './pages/biometric-Device/assign-device/assign-device.component';
import { ViewDeviceComponent } from './pages/biometric-Device/view-device/view-device.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ExcelReportComponent } from './pages/attendance/excel-report/excel-report.component';
import { PlanTypesComponent } from './pages/Plans/plan-types/getPlanTypes/plan-types.component';
import { PlanSubTypesComponent } from './pages/Plans/plan-sub-types/getPlanSubType/plan-sub-types.component';
import { PlanComponent } from './pages/Plans/plan/getPlans/plan.component';
import { CreatePlanComponent } from './pages/Plans/plan/createPlans/create-plan.component';
import { CreatePlanTypesComponent } from './pages/Plans/plan-types/createPlanTypes/create-plan-types.component';
import { CreatePlanSubTypeComponent } from './pages/Plans/plan-sub-types/createPlanSubType/create-plan-sub-type/create-plan-sub-type.component';
import { ViewPlanSubTypeComponent } from './pages/Plans/plan-sub-types/view-plan-sub-type/view-plan-sub-type.component';
import { ViewPlansComponent } from './pages/Plans/plan/view-plans/view-plans.component';
import { GetAddOnPlanComponent } from './pages/Plans/add-on-plan/get-add-on-plan/get-add-on-plan.component';
import { ViewAddOnPlanComponent } from './pages/Plans/add-on-plan/view-add-on-plan/view-add-on-plan.component';
import { CreateAddOnPlanComponent } from './pages/Plans/add-on-plan/create-add-on-plan/create-add-on-plan.component';;
import { GetAddOnTypesComponent } from './pages/Plans/add-on-types/get-add-on-types/get-add-on-types.component';
import { ViewAddOnTypesComponent } from './pages/Plans/add-on-types/view-add-on-types/view-add-on-types.component';
import { CreateAddOnTypesComponent } from './pages/Plans/add-on-types/create-add-on-types/create-add-on-types.component';
import { ActiveSchedulesListComponent } from './pages/schedules/active-schedules-list/active-schedules-list.component';
import { UserSchedulesListComponent } from './pages/schedules/user-schedules-list/user-schedules-list.component';
import { ActiveLeaveListComponent } from './pages/leave-management/active-leave-list/active-leave-list.component';
import { UserLeaveListComponent } from './pages/leave-management/user-leave-list/user-leave-list.component';
import { CreateLeaveComponent } from './pages/leave-management/create-leave/create-leave.component';
import { UpdateLeaveStatusComponent } from './pages/leave-management/update-leave-status/update-leave-status.component';
import { GetUserPayableComponent } from './pages/Users/get-user-payable/get-user-payable.component';
import { CreateUserPayableComponent } from './pages/Users/create-user-payable/create-user-payable.component';
import { CreateScheduleComponent } from './pages/schedules/create-schedule/create-schedule.component';
import { GetCouponTypesComponent } from './pages/Coupons/get-coupon-types/get-coupon-types.component';
import { CreateCouponTypesComponent } from './pages/Coupons/create-coupon-types/create-coupon-types.component';
import { GetCouponComponent } from './pages/Coupons/get-coupon/get-coupon.component';
import { CreateCouponComponent } from './pages/Coupons/create-coupon/create-coupon.component';
import { PayrollReportComponent } from './pages/reports/payroll-reports/payroll-report/payroll-report.component';
import { TicketsListComponent } from './pages/support/Tickets/tickets-list/tickets-list.component';
import { CreateTicketComponent } from './pages/support/Tickets/create-ticket/create-ticket.component';
import { EditTicketComponent } from './pages/support/Tickets/edit-ticket/edit-ticket.component';
import { ViewTicketComponent } from './pages/support/Tickets/view-ticket/view-ticket.component';
import { ReschedulesListComponent } from './pages/schedules/reschedules-list/reschedules-list.component';
import { UpdateRescheduleComponent } from './pages/schedules/update-reschedule/update-reschedule.component';
import { TimeSlotDiscountsComponent } from './pages/Plans/timeslot-discounts/getTimeslotDiscounts/timeslot-discounts.component';
import { CreateTimeSlotDiscountsComponent } from './pages/Plans/timeslot-discounts/create-timeslot-discounts/create-timeslot-discounts.component';
import { UsageReportComponent } from './pages/reports/usage-reports/usage-report/usage-report.component';
import { ReportsComponent } from './pages/reports/usage-reports/view-usage-reports/view-usage-reports.component';
import { PersonalTrainingComponent } from './pages/dashboard/personalTrainingSessions/pts-component';
import { FileUploadComponent } from './pages/support/faqs/upload-faqs/upload-faqs.component';
import { HttpClientModule } from '@angular/common/http';
import { TagsComponent } from './pages/support/tags/get-tags/tags.component';
import { CreateTagComponent } from './pages/support/tags/create-tag/create-tag.component';
import { DragNDropDirective } from './pages/dragNDrop/dnd.directive';
import { ProgressComponent } from './pages/dragNDrop/progress/progress.component';
import { ViewPayrollReportsComponent } from './pages/reports/payroll-reports/view-payroll-reports/view-payroll-reports.component';
import { DateFormatPipe, DateTimeFormatPipe, TimeFormatPipe, SentenceWithUnderscoreTitleCase } from './customDatePipes';
import { ViewCouponComponent } from './pages/Coupons/view-coupon/view-coupon.component';
import { FileUploadManagerComponent } from './pages/commonComponents/file-upload-manager/file-upload-manager.component';
import { UserPurchaseHistoryComponent } from './pages/purchaseHistory/user-purchase-history/user-purchase-history.component';
import { VerifyDeactivateComponent } from './pages/commonComponents/verify-deactivate/verify-deactivate.component';
import { CancelPlanComponent } from './pages/purchaseHistory/cancel-plan/cancel-plan.component';
import { UserWalletsListComponent } from './pages/userWallets/user-wallets-list/user-wallets-list.component';
import { ReferralPercentagesManagementComponent } from './pages/referralPercentages/referral-percentages-management/referral-percentages-management.component';
import { CreateReferralPercentagesComponent } from './pages/referralPercentages/create-referral-percentages/create-referral-percentages.component';
import { ViewUserAccessesComponent } from './pages/userAccesses/view-user-accesses/view-user-accesses.component';
import { EditUserAccessesComponent } from './pages/userAccesses/edit-user-accesses/edit-user-accesses.component';
import { ChangePlanTrainerComponent } from './pages/purchaseHistory/change-plan-trainer/change-plan-trainer.component';
import { SharedComponentsModule } from './modules/shared-components/shared-components.module';
import { PlanExpiryRenewalTracksManagementComponent } from './pages/planExpiryRenewalTracks/plan-expiry-renewal-tracks-management/plan-expiry-renewal-tracks-management.component';
import { PlanWaitlistsManagementComponent } from './pages/planWaitlists/plan-waitlists-management/plan-waitlists-management.component';
import { FreezeAppAlertsListComponent } from './pages/freezeAppAlerts/freeze-app-alerts-list/freeze-app-alerts-list.component';
import { CreateFreezeAppAlertComponent } from './pages/freezeAppAlerts/create-freeze-app-alert/create-freeze-app-alert.component';
import { ViewFreezeAppAlertComponent } from './pages/freezeAppAlerts/view-freeze-app-alert/view-freeze-app-alert.component';
import { ExtendUserOrderPlanComponent } from './pages/purchaseHistory/extend-user-order-plan/extend-user-order-plan.component';
import { UpdateUserRoleComponent } from './pages/Users/update-user-role/update-user-role.component';
import { FitnessStatListComponent } from './pages/fitnessStats/fitness-stat-list/fitness-stat-list.component';
import { ViewFitnessStatsComponent } from './pages/fitnessStats/view-fitness-stats/view-fitness-stats.component';
import { ChangePlanTimeTrainerComponent } from './pages/purchaseHistory/change-plan-time-trainer/change-plan-time-trainer.component';
import { ReassignmentsComponent } from './pages/schedules/reassignments/reassignments.component';

const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent,
        UserManagementComponent,
        RolesListComponent,
        CreateRoleComponent,
        CreateUserComponent,
        ViewRoleComponent,
        ViewUserComponent,
        BranchesListComponent,
        CreateBranchComponent,
        SearchPipe,
        OtpConformationComponent,
        ViewBranchComponent,
        ProfileComponent,
        AttendanceListComponent,
        AddAttendanceComponent,
        ViewUserAttendanceComponent,
        CreateLogsComponent,
        ViewUserLogsComponent,
        DevicesComponent,
        CreateDeviceComponent,
        AssignDeviceComponent,
        ViewDeviceComponent,
        ExcelReportComponent,
        PlanTypesComponent,
        PlanSubTypesComponent,
        PlanComponent,
        CreatePlanComponent,
        CreatePlanTypesComponent,
        CreatePlanSubTypeComponent,
        ViewPlanSubTypeComponent,
        ViewPlansComponent,
        GetAddOnPlanComponent,
        ViewAddOnPlanComponent,
        CreateAddOnPlanComponent,
        GetAddOnTypesComponent,
        ViewAddOnTypesComponent,
        CreateAddOnTypesComponent,
        ActiveSchedulesListComponent,
        UserSchedulesListComponent,
        ActiveLeaveListComponent,
        UserLeaveListComponent,
        CreateLeaveComponent,
        UpdateLeaveStatusComponent,
        GetUserPayableComponent,
        CreateUserPayableComponent,
        CreateScheduleComponent,
        GetCouponTypesComponent,
        CreateCouponTypesComponent,
        GetCouponComponent,
        CreateCouponComponent,
        PayrollReportComponent,
        TicketsListComponent,
        CreateTicketComponent,
        EditTicketComponent,
        ViewTicketComponent,
        ReschedulesListComponent,
        UpdateRescheduleComponent,
        TimeSlotDiscountsComponent,
        CreateTimeSlotDiscountsComponent,
        ReportsComponent,
        UsageReportComponent,
        PersonalTrainingComponent,
        FileUploadComponent,
        TagsComponent,
        CreateTagComponent,
        DragNDropDirective,
        ProgressComponent,
        ViewPayrollReportsComponent,
        DateFormatPipe,
        TimeFormatPipe,
        DateTimeFormatPipe,
        SentenceWithUnderscoreTitleCase,
        ViewCouponComponent,
        FileUploadManagerComponent,
        UserPurchaseHistoryComponent,
        VerifyDeactivateComponent,
        CancelPlanComponent,
        UserWalletsListComponent,
        ReferralPercentagesManagementComponent,
        CreateReferralPercentagesComponent,
        ViewUserAccessesComponent,
        EditUserAccessesComponent,
        ChangePlanTrainerComponent,
        PlanExpiryRenewalTracksManagementComponent,
        PlanWaitlistsManagementComponent,
        FreezeAppAlertsListComponent,
        CreateFreezeAppAlertComponent,
        ViewFreezeAppAlertComponent,
        ExtendUserOrderPlanComponent,
        UpdateUserRoleComponent,
        FitnessStatListComponent,
        ViewFitnessStatsComponent,
        ChangePlanTimeTrainerComponent,
        ReassignmentsComponent,
    ],
    imports : [
        SharedComponentsModule.forRoot(),
        BrowserModule,
        MatDialogModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        MatSelectModule,
        MatAutocompleteModule,
        MatDividerModule,
        MatIconModule,
        FuseCardModule,
        NgOtpInputModule,
        MatInputModule,
        MatSnackBarModule,
        MatTimepickerModule,      
        MatCheckboxModule,
        MatMenuModule,
        MatPaginatorModule,
        NgxMatTimepickerModule,
        MatTableModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot({
            timeOut: 4000,
            positionClass: 'toast-top-right',
            closeButton: true,
            progressBar: true,
            preventDuplicates: true,
          }), // ToastrModule added
        MatRadioModule,
        MatButtonModule,
        MatSortModule,
        MatTooltipModule,
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,
        HttpClientModule
    ],
    providers: [DatePipe],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule{ }
